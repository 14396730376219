$mainWhite: #ffffff;
$navGreen: #27625b;
$background: #f5f5f5;
$linkGreen: #1f4f46;
$mainPink: #f5a0a0;
$mainGrey: #aeaca5;
$mainText: #555e61;

.navbar {
  background-color: $mainWhite;
  border: bottom;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
  min-height: 70px;
}

/* ALL PAGES */
#nav-link {
  transition: transform 250ms;
  display: inline-block;
  color: $navGreen;
  transition: all 0.3s linear;
  font-size: 13px;
  padding: 0 35px 0 0;
}
#nav-link:hover {
  transform: translateY(-2px);
}

.a-page {
  /* background-color: #fafff2; */
  background-color: $background;
  /* #fafff2; */
  font-family: "Poppins", sans-serif;
  height: 100%;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  padding-bottom: 20px;
}

#teachid {
  color: $linkGreen;
  font-weight: 600;
  text-decoration: none;
}

#linkid {
  color: $linkGreen;
  font-weight: 600;
  text-decoration: none;
  /* border-bottom: 1px solid $linkGreen; */
  padding-bottom: 0.15em;
}

#linkid:hover {
  color: $linkGreen;
  cursor: pointer;
  text-decoration: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23f5a0a0' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 20%;
  border-bottom: 0;
  padding-bottom: 0.2em;
}

/*ABOUT PAGE*/
.a-profile {
  width: 170px;
  height: 170px;
  /* margin-right: 160px; */
  /* margin-top: 20px; */
}

.a-profile-entire-hero {
  padding-top: 55px;
  padding-bottom: 3px;
  justify-content: center;
}

.a-tomato {
  width: 50px;
  height: auto;
  transform: scale(0, 0);
  transition: 1s;
}

#linkid-tomatoimage {
  height: 20px;
  width: auto;
}

#linkid-tomatotext {
  color: $linkGreen;
  text-decoration: none;
}

#linkid-tomatotext:hover #linkid-tomatoimage {
  transform: scale(2, 2);
}

#linkid-longer {
  color: $linkGreen;
  font-weight: 600;
  text-decoration: none;
  /* border-bottom: 1px solid #3a4245; */
  padding-bottom: 0.15em;
}

#linkid-longer:hover {
  color: $linkGreen;
  cursor: pointer;
  text-decoration: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23f5a0a0' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 12%;
  border-bottom: 0;
  padding-bottom: 0.2em;
}

/*WORK PAGE*/
.a-slider {
  width: 300px;
  height: 300px;
}

/*RESUME PAGE*/
.a-document {
  /* padding: 0px 0px 100px 0px; */
  margin-bottom: 100px;
}

.a-link {
  color: $linkGreen;
}

/*CONTACT PAGE*/
.icon-contact {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

#linkid-contact {
  color: $mainGrey;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s linear;
  transition: transform 250ms;
  border-bottom: 0;
  padding-bottom: 0.2em;
}

#linkid-contact:hover {
  color: $linkGreen;
  cursor: pointer;
  text-decoration: none;
  transform: translateY(-2px);
  border-bottom: 0;
  padding-bottom: 0.2em;
}
