@import "../../index.scss";

.a-content {
  margin-bottom: 80px;
  color: $mainText;
  line-height: 30px;
  font-weight: 400;

  /* font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px; */

  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
}
