/*HERO COMPONENT*/
.a-entire-hero {
  padding-top: 55px;
  padding-bottom: 10px;
  justify-content: center;
}

.a-hero-title {
  font-size: 80px;
  font-weight: 600;
  color: #4a685a;
}

.a-hero-quote {
  font-size: 19px;
  font-weight: 240;
  color: #61645e;
  /* color: #62625b; */
  font-style: italic;
  /* font-weight; */
}

.a-hero-name {
  font-size: 13px;
  font-weight: 300;
  color: #aeaca5;
}
